/* eslint-disable import/no-internal-modules */
/* eslint-disable boundaries/element-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { forwardRef, useEffect, useRef } from "react";

import { useAccount } from "user_site/features/account";
import {
  ManageFavoritesModalV3,
  useFavoritesLists,
  useAddProductToFavoritesList,
  useRemoveProductFromFavoritesList,
} from "user_site/features/favorites";
import { WithProviders } from "user_site/utils";

const ManageFavoritesIconButtonInner = (
  {
    productId,
    isProductFavorited,
  }: {
    productId: number;
    isProductFavorited: boolean;
  },
  ref: any
) => (
  <ManageFavoritesModalV3
    trigger={
      <button
        id="manage_product_favorite"
        className="submit-form-btn manage-product-favorite secondary-button"
        type="button"
        ref={ref}
      >
        {isProductFavorited ? (
          <i className="fa-solid fa-heart warning" />
        ) : (
          <i className="fa-regular fa-heart" />
        )}
      </button>
    }
    productId={productId}
  />
);

const ManageFavoritesIconButton = forwardRef(ManageFavoritesIconButtonInner);

const SaveToFavoritesGuestIconButtonInner = (_: any, ref: any) => {
  const { data: user } = useAccount();

  if (!user || !user.isGuest) return null;

  return (
    <a
      className="submit-form-btn secondary-button"
      ref={ref}
      href={user.metadata.loginUrl}
    >
      <i className="fa-regular fa-heart" />
    </a>
  );
};

const SaveToFavoritesGuestIconButton = forwardRef(
  SaveToFavoritesGuestIconButtonInner
);

const SaveToFavoritesIconButtonInner = (
  {
    productId,
    favoriteListId,
  }: {
    productId: number;
    favoriteListId: number;
  },
  ref: any
) => {
  const { mutate } = useAddProductToFavoritesList({
    productId,
    favoriteListId,
  });

  const handleAddProductToFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <form className="no-left-padding" id="create_product_favorite" ref={ref}>
      <button
        className="submit-form-btn secondary-button"
        type="button"
        onClick={handleAddProductToFavoritesButtonClick}
      >
        <i className="fa-regular fa-heart" />
      </button>
    </form>
  );
};

const SaveToFavoritesIconButton = forwardRef(SaveToFavoritesIconButtonInner);

const RemoveFromFavoritesIconButtonInner = (
  {
    productId,
    favoriteListId,
  }: {
    favoriteListId: number;
    productId: number;
  },
  ref: any
) => {
  const { mutate } = useRemoveProductFromFavoritesList({
    productId,
    favoriteListId,
  });

  const handleRemoveFromFavoritesButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = (e) => {
    e.stopPropagation();
    mutate();
  };

  return (
    <button
      className="submit-form-btn secondary-button"
      type="button"
      ref={ref}
      onClick={handleRemoveFromFavoritesButtonClick}
    >
      <i className="fa-solid fa-heart warning" />
    </button>
  );
};

const RemoveFromFavoritesIconButton = forwardRef(
  RemoveFromFavoritesIconButtonInner
);

const Form = ({ productId }: { productId: number }) => {
  const ref = useRef<any | null>(null);
  const { data: user } = useAccount();
  const { data: favoriteLists } = useFavoritesLists();

  const isProductFavorited = !!favoriteLists?.some((list) =>
    list.favoritedProductsIds.find((i) => i === productId)
  );

  useEffect(() => {
    // adding proper css class to parent container
    // this allows to avoid styles refactoring
    if (ref.current) {
      if (isProductFavorited) {
        ref.current.parentNode.classList.add("favorited");
        ref.current.parentNode.classList.remove("not-favorited");
      } else {
        ref.current.parentNode.classList.add("not-favorited");
        ref.current.parentNode.classList.remove("favorited");
      }
    }
  }, [isProductFavorited, productId]);
  if (!user || !favoriteLists) return null;

  if (user.isGuest) return <SaveToFavoritesGuestIconButton />;

  if (favoriteLists.length > 1)
    return (
      <ManageFavoritesIconButton
        productId={productId}
        isProductFavorited={isProductFavorited}
        ref={ref}
      />
    );

  const favoriteListId = favoriteLists[0]?.id;

  if (!favoriteListId) return null;

  if (isProductFavorited)
    return (
      <RemoveFromFavoritesIconButton
        productId={productId}
        favoriteListId={favoriteListId}
        ref={ref}
      />
    );

  return (
    <SaveToFavoritesIconButton
      productId={productId}
      favoriteListId={favoriteListId}
      ref={ref}
    />
  );
};

const ProductGalleryFavoriteButton = ({ productId }: { productId: number }) => (
  <Form productId={productId} />
);

const Component = ({ productId }: { productId: number }) => (
  <WithProviders>
    <ProductGalleryFavoriteButton productId={productId} />
  </WithProviders>
);

export default Component;
