/* eslint-disable import/no-internal-modules */
import { AccountPanelMobileV2 } from "user_site/features/account";
import { WithProviders } from "user_site/utils";

const Component = () => (
  <WithProviders>
    <AccountPanelMobileV2 />
  </WithProviders>
);

export default Component;
