import { useAccount } from "user_site/features/account/model";

export const AccountPanelMobile = () => {
  const { data: user } = useAccount();

  if (!user) return null;

  if (user.isGuest)
    return (
      <>
        <li>
          <a href={user.metadata.loginUrl}>Favorites</a>
        </li>

        <li className="divider" />

        <li>
          <a
            id="create_account_mobile_nav"
            href={user.metadata.createAccountUrl}
          >
            Create an Account
          </a>
        </li>

        <li className="divider" />

        <li>
          <a href={user.metadata.loginUrl}>Login</a>
        </li>
      </>
    );

  return (
    <>
      <li>
        <a href={`/apps/users/${user.id}/favorite_lists`}>Favorites</a>
      </li>

      <li className="divider" />

      <li>
        <a href={`/apps/users/${user.id}/account`}>
          {user.username ?? user.email}
        </a>
      </li>

      <li className="divider" />

      <li>
        <a id="logout" href={user.metadata.logoutUrl}>
          Logout
        </a>
      </li>
    </>
  );
};
