import { useDeferredValue, useState } from "react";

export const SiteSearchFormMobile = () => {
  const [isActive, setIsActive] = useState(false);
  const isActiveDeferred = useDeferredValue(isActive);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue && inputValue.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  return (
    <form
      action="/site_search"
      method="get"
      id="navbar-v3-side-menu-search"
      className={isActiveDeferred ? "active-search-input" : ""}
    >
      <input
        type="text"
        name="search"
        placeholder="Search..."
        autoComplete="on"
        onChange={handleSearchInputChange}
      />
    </form>
  );
};
