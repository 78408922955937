/* eslint-disable no-restricted-imports */
/* eslint-disable boundaries/element-types */
import { createPortal } from "react-dom";

import { useAccount } from "user_site/features/account/model";
import { useFavoritesLists } from "user_site/features/favorites/model";

import { AddFavoriteList } from "./AddFavoriteList";

export const NavigationPanelFavorites = () => {
  const { data: user } = useAccount();
  const { data: favoriteLists } = useFavoritesLists();

  if (!user || !favoriteLists) return null;

  return (
    <>
      <a
        className="account-links"
        id="favorite_lists_dropdown"
        data-options="is_hover:true"
        data-dropdown="favorite_lists"
        href={`/apps/users/${user.id}/favorite_lists`}
      >
        Favorites
        <i className="fa-solid fa-caret-down" />
      </a>

      {createPortal(
        <ul
          id="favorite_lists"
          className="f-dropdown favorite-lists"
          data-dropdown-content
        >
          {favoriteLists.map((favoriteList) => (
            <li key={favoriteList.id}>
              <a href={favoriteList.url}>
                {`${favoriteList.name} (${favoriteList.productFavoritesCount})`}
              </a>
            </li>
          ))}

          <li>
            <AddFavoriteList />
          </li>
          <li>
            <a
              id="favorite_lists"
              href={`/apps/users/${user.id}/favorite_lists`}
            >
              <i className="fa-solid fa-pencil" />
              &nbsp;Manage Favorite Lists
            </a>
          </li>
        </ul>,
        document.body
      )}
    </>
  );
};
