/* eslint-disable jsx-a11y/anchor-is-valid */
import { forwardRef, type LinkHTMLAttributes } from "react";

const SiteSearchIconButtonInnner = (
  props: LinkHTMLAttributes<any>,
  ref: any
) => (
  <a
    {...props}
    ref={ref}
    className="navigation-icons"
    id="search-item"
    href="#"
  >
    <img
      src="/assets/user_site/theme_v3/navigation_bar/search.png"
      alt="account"
    />
  </a>
);

export const SiteSearchIconButton = forwardRef(SiteSearchIconButtonInnner);
