/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */
import { useQuery } from "@tanstack/react-query";

import { API } from "../../api";

interface AuthenticatedUser {
  id: number;
  username: string | null;
  email: string;
  isGuest: false;
  metadata: {
    logoutUrl: string;
    disableGuestUserCreationFeatureEnabled: boolean;
  };
}

interface GuestUser {
  id: number;
  isGuest: true;
  username: null;
  email: null;
  metadata: {
    loginUrl: string;
    createAccountUrl: string;
    disableGuestUserCreationFeatureEnabled: boolean;
  };
}

export type User = GuestUser | AuthenticatedUser;

export const useAccount = () =>
  useQuery<User>({
    queryKey: ["me_query_key"],
    queryFn: () => API.get("/api/v1/user_site/users/me").then((r) => r.data),
  });
