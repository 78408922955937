import { createContext, useState } from "react";

import { type DialogProps, Root } from "@radix-ui/react-dialog";

export const DialogContext = createContext<any | null>(null);

export const Dialog = ({
  children,
  renderToBody = false,
  ...rest
}: DialogProps & { renderToBody?: boolean }) => {
  const [container, setContainer] = useState<HTMLElement | null>(
    renderToBody ? document.body : null
  );

  return (
    <DialogContext.Provider value={container}>
      <Root {...rest}>
        {children}

        {!renderToBody && <div ref={setContainer} id="dialog-container" />}
      </Root>
    </DialogContext.Provider>
  );
};
