/* eslint-disable import/no-internal-modules */
/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { API } from "../../api";
import { toast } from "../../lib/toast";

export interface FavoriteList {
  id: number;
  name: string;
  productFavoritesCount: number;
  url: string;
  favoritedProductsIds: number[];
  isPrivate: boolean;
  isPersisted: boolean;
}

export const useFavoritesLists = () =>
  useQuery<FavoriteList[]>({
    queryKey: ["favorite_lists_query_key"],
    queryFn: () =>
      API.get("/api/v1/user_site/favorite_lists").then((r) => r.data),
  });

export const useAddFavoriteList = () => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, { name: string }>({
    mutationKey: ["add_favorite_list_query_key"],
    mutationFn: ({ name }: { name: string }) =>
      API.post("/api/v1/user_site/favorite_lists", { name }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["favorite_lists_query_key"] });
    },
  });
};

export const useUpdateFavoriteList = ({
  favoriteListId,
}: {
  favoriteListId: number;
}) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, { isPrivate: boolean }>({
    mutationKey: ["update_favorite_list_query_key", favoriteListId],
    mutationFn: ({ isPrivate }: { isPrivate: boolean }) =>
      API.put(`/api/v1/user_site/favorite_lists/${favoriteListId}`, {
        is_private: isPrivate,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["favorite_lists_query_key"] });
    },
  });
};

export const useAddProductToFavoritesList = ({
  productId,
  favoriteListId,
}: {
  productId: number;
  favoriteListId: number;
}) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, void>({
    mutationKey: ["add_product_to_favorites_list", productId, favoriteListId],
    mutationFn: () =>
      API.post(`/api/v1/user_site/favorite_lists/${favoriteListId}/products`, {
        product_id: productId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["favorite_lists_query_key"] });

      toast.success({
        message: '<i class="fa-solid fa-heart"></i> Saved to Favorites',
      });
    },
  });
};

export const useRemoveProductFromFavoritesList = ({
  productId,
  favoriteListId,
}: {
  productId: number;
  favoriteListId: number;
}) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, void>({
    mutationKey: [
      "remove_product_from_favorites_list",
      productId,
      favoriteListId,
    ],
    mutationFn: () =>
      API.delete(
        `/api/v1/user_site/favorite_lists/${favoriteListId}/products/${productId}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["favorite_lists_query_key"] });

      toast.success({
        message: '<i class="fa-regular fa-heart"></i> Removed from Favorites',
      });
    },
  });
};
