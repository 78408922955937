/* eslint-disable import/no-internal-modules */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */

import { useRef, useState } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "user_site/components";
import { useAddFavoriteList } from "user_site/features/favorites/model";

export const AddFavoriteList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate: addFavoriteList } = useAddFavoriteList();

  const handleAddFavoriteList = () => {
    const value = inputRef.current?.value;

    if (value && value.length > 0) {
      addFavoriteList({ name: value }, { onSuccess: () => setIsOpen(false) });
    } else {
      inputRef.current?.focus();
    }
  };

  return (
    <Dialog renderToBody open={isOpen}>
      <DialogTrigger asChild>
        <a href="#" onClick={() => setIsOpen(true)}>
          <i className="fa-solid fa-plus" />
          &nbsp; Add Favorite List
        </a>
      </DialogTrigger>
      <DialogContent
        id="new_favorite_list_modal"
        className="react-reveal-modal small open"
        style={{
          marginLeft: "initial",
        }}
        onInteractOutside={() => setIsOpen(false)}
      >
        <DialogClose asChild>
          <button
            aria-label="close"
            type="button"
            style={{
              fontFamily: "inherit",
              border: "none",
              backgroundColor: "inherit",
              zIndex: 10,
              lineHeight: 1,
              position: "absolute",
              top: 0,
              right: 0,
              padding: "10px 20px",
              fontSize: "25px",
              color: "black",
            }}
            onClick={() => setIsOpen(false)}
          >
            &#215;
          </button>
        </DialogClose>

        <h2>Create a New Favorite List</h2>

        <form
          className="no-left-margin no-left-padding"
          id="new_favorite_list"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="large-9 columns user-form-field">
            <div className="user-form-field-label">
              Name
              <input
                type="text"
                name="favorite_list[name]"
                id="favorite_list_name"
                ref={inputRef}
              />
            </div>
          </div>

          <div className="large-9 columns user-form-field">
            <div className="user-form-field-label">
              <input
                name="commit"
                type="submit"
                value="Create"
                className="submit-form-btn"
                onClick={handleAddFavoriteList}
              />
              <br />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
