/* eslint-disable import/no-internal-modules */
import { type MouseEventHandler } from "react";

import { useAccount } from "user_site/features/account";
import {
  ManageFavoritesModalV3,
  useFavoritesLists,
  useAddProductToFavoritesList,
  useRemoveProductFromFavoritesList,
} from "user_site/features/favorites";
import { WithProviders } from "user_site/utils";

const MagnifierIcon = () => (
  <svg className="icon-magnifier" viewBox="-66 68.8 65.2 65.2">
    <path
      className="icon-magnifier__external"
      d="M-2.4 129.5l-12.9-12.8c4.5-5.1 6.9-11.6 6.9-18.5 0-7.6-2.9-14.7-8.3-20-5.3-5.5-12.4-8.4-20-8.4s-14.7 2.9-20 8.3c-11 11-11 29 0 40.1 5.4 5.4 12.5 8.3 20 8.3 6.9 0 13.4-2.4 18.5-6.9l12.9 12.9c.8.8 2.1.8 2.9 0 .8-.9.8-2.2 0-3zm-51.4-14.2c-9.5-9.5-9.5-24.8 0-34.3 4.6-4.6 10.7-7.1 17.1-7.1 6.5 0 12.6 2.5 17.1 7.1 4.6 4.6 7.1 10.7 7.1 17.1 0 6.5-2.5 12.6-7.1 17.1-4.6 4.6-10.7 7.1-17.1 7.1-6.5.1-12.5-2.4-17.1-7z"
      stroke="#000"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <circle
      className="icon-magnifier__inner-circle"
      cx="-36.7"
      cy="98.1"
      r="18.6"
    />
  </svg>
);

const FavoriteIcon = () => (
  <svg className="favorite-svg" viewBox="0 0 521.3 476.2">
    <path
      className="favorite-svg-back"
      d="M35 163.4c.5-57 37.5-107.4 92.2-122.9 45.4-12.8 86.5-3.2 122.3 28 2.9 2.5 5.8 5.1 8.3 7.9 2.1 2.3 3.3 2.1 5.5-.1 12.1-12.1 25.4-22.7 41.2-29.5 44.8-19.1 87.7-15.5 127.8 12.5 28 19.7 45.7 46.7 51.6 80.3 7.2 40.9-1.7 78.2-30.4 108.8-41.5 44.3-85.4 86.2-128.1 129.2-20.4 20.6-41.1 40.9-61.5 61.5-2.6 2.7-3.9 2.7-6.6 0-61-61.2-122.2-122.4-183.3-183.5-21.4-21.3-34.2-46.8-38.1-76.7-.6-5.1-.6-10.3-.9-15.5z"
      fill="transparent"
      stroke="#000"
      strokeWidth="70"
      strokeMiterlimit="10"
    />
    <path
      className="favorite-svg-front"
      d="M35 163.4c.5-57 37.5-107.4 92.2-122.9 45.4-12.8 86.5-3.2 122.3 28 2.9 2.5 5.8 5.1 8.3 7.9 2.1 2.3 3.3 2.1 5.5-.1 12.1-12.1 25.4-22.7 41.2-29.5 44.8-19.1 87.7-15.5 127.8 12.5 28 19.7 45.7 46.7 51.6 80.3 7.2 40.9-1.7 78.2-30.4 108.8-41.5 44.3-85.4 86.2-128.1 129.2-20.4 20.6-41.1 40.9-61.5 61.5-2.6 2.7-3.9 2.7-6.6 0-61-61.2-122.2-122.4-183.3-183.5-21.4-21.3-34.2-46.8-38.1-76.7-.6-5.1-.6-10.3-.9-15.5z"
      fill="#fff"
    />
  </svg>
);

const ManageFavoritesIconButton = ({
  productId,
  isProductFavorited,
}: {
  productId: number;
  isProductFavorited: boolean;
}) => {
  console.log(1, productId);

  return (
    <ManageFavoritesModalV3
      trigger={
        <div
          className={`product-card__favorite favorite-forms-container ${
            isProductFavorited ? "favorited" : "not-favorited"
          }`}
        >
          <button
            className="manage-product-favorite save-to-favorites submit-form-btn"
            type="button"
          >
            <i className="fa-regular fa-heart" />
          </button>
        </div>
      }
      productId={productId}
    />
  );
};

const SaveToFavoritesGuestIconButton = () => {
  const { data: user } = useAccount();

  if (!user || !user.isGuest) return null;

  return (
    <div className="product-card__favorite favorite-forms-container not-favorited">
      <a
        className="submit-form-btn secondary-button"
        href={user.metadata.loginUrl}
      >
        <i className="fa-regular fa-heart" />
      </a>
    </div>
  );
};

const SaveToFavoritesIconButton = ({
  productId,
  favoriteListId,
}: {
  productId: number;
  favoriteListId: number;
}) => {
  const { mutate } = useAddProductToFavoritesList({
    productId,
    favoriteListId,
  });

  const handleAddProductToFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <div className="product-card__favorite favorite-forms-container not-favorited">
      <form>
        <button
          className="submit-form-btn secondary-button"
          type="button"
          onClick={handleAddProductToFavoritesButtonClick}
        >
          <i className="fa-regular fa-heart" />
        </button>
      </form>
    </div>
  );
};

const RemoveFromFavoritesIconButton = ({
  productId,
  favoriteListId,
}: {
  favoriteListId: number;
  productId: number;
}) => {
  const { mutate } = useRemoveProductFromFavoritesList({
    productId,
    favoriteListId,
  });

  const handleRemoveFromFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <div
      className="product-card__favorite favorite-forms-container favorited"
      data-product-id="<%= product_presenter.id %>"
    >
      <form>
        <button
          className="submit-form-btn secondary-button"
          type="button"
          onClick={handleRemoveFromFavoritesButtonClick}
        >
          <i className="fa-regular fa-heart" />
        </button>
      </form>
    </div>
  );
};

const Form = ({ productId }: { productId: number }) => {
  const { data: user } = useAccount();
  const { data: favoriteLists } = useFavoritesLists();

  if (!user || !favoriteLists) return null;

  if (user.isGuest) return <SaveToFavoritesGuestIconButton />;

  const isProductFavorited = !!favoriteLists?.some((list) =>
    list.favoritedProductsIds.find((i) => i === productId)
  );

  if (favoriteLists.length > 1)
    return (
      <ManageFavoritesIconButton
        productId={productId}
        isProductFavorited={isProductFavorited}
      />
    );

  const favoriteListId = favoriteLists[0]?.id;

  if (!favoriteListId) return null;

  if (isProductFavorited)
    return (
      <RemoveFromFavoritesIconButton
        productId={productId}
        favoriteListId={favoriteListId}
      />
    );

  return (
    <SaveToFavoritesIconButton
      productId={productId}
      favoriteListId={favoriteListId}
    />
  );
};

declare global {
  interface Window {
    // using globally defined quick view button handler
    handleQuickViewButtonClick: (...props: any) => any;
  }
}

const QuickViewButton = ({ productUrl }: { productUrl: string }) => {
  const handleQuickViewButtonClick: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();

    window.handleQuickViewButtonClick(e);
  };

  return (
    <div className="product-card__quick-view-wrap">
      <div className="product-card__note">QUICK VIEW</div>
      <button
        type="button"
        className="product-card__quick-view"
        data-href={productUrl}
        onClick={handleQuickViewButtonClick}
      >
        <MagnifierIcon />
      </button>
    </div>
  );
};

const ProductGalleryProductCardControls = ({
  productId,
  productUrl,
  isQuickViewEnabled,
}: {
  productId: number;
  productUrl: string;
  isQuickViewEnabled: boolean;
}) => (
  <div className="product-card__controls">
    {isQuickViewEnabled && <QuickViewButton productUrl={productUrl} />}

    <Form productId={productId} />

    <div className="product-card__favorite-hover">
      <div className="product-card__favorite-notes">
        <div className="product-card__note product-card__favorite-note product-card__favorite-note_added">
          REMOVE FROM FAVORITES
        </div>
        <div className="product-card__note product-card__favorite-note product-card__favorite-note_removed">
          ADD TO FAVORITES
        </div>
      </div>
      <div className="product-card__favorite-icon">
        <FavoriteIcon />
      </div>
    </div>
  </div>
);

const Component = ({
  productId,
  productUrl,
  isQuickViewEnabled,
}: {
  productId: number;
  productUrl: string;
  isQuickViewEnabled: boolean;
}) => (
  <WithProviders>
    <ProductGalleryProductCardControls
      productId={productId}
      productUrl={productUrl}
      isQuickViewEnabled={isQuickViewEnabled}
    />
  </WithProviders>
);

export default Component;
