/* eslint-disable boundaries/element-types */
/* eslint-disable import/no-internal-modules */
import { useAccount } from "user_site/features/account/model";
import { NavigationPanelFavorites } from "user_site/features/favorites/ui/v2";

export const AccountPanel = () => {
  const { data: user } = useAccount();

  if (!user) return null;

  return (
    <>
      {!user.metadata.disableGuestUserCreationFeatureEnabled && (
        <NavigationPanelFavorites />
      )}

      {user.isGuest ? (
        <>
          <a
            className="account-links"
            id="create_account_main_nav"
            href={user.metadata.createAccountUrl}
          >
            Create an Account
          </a>

          <a
            className="account-links"
            id="login_main_nav"
            href={user.metadata.loginUrl}
          >
            Login
          </a>
        </>
      ) : (
        <>
          <a
            className="account-links"
            id="show_user"
            href={`/apps/users/${user.id}/account`}
          >
            {user.username ?? user.email}
          </a>

          <a
            className="account-links"
            id="logout"
            href={user.metadata.logoutUrl}
          >
            Logout
          </a>
        </>
      )}
    </>
  );
};
