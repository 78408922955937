import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
  useContext,
} from "react";

import { DialogPortal } from "@radix-ui/react-dialog";
import { Content } from "@radix-ui/react-dialog";

import { DialogContext } from "./Dialog";
import { DialogOverlay } from "./Overlay";

const DialogContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content & { container: any }>
>(({ children, className, ...props }, ref) => {
  const container = useContext(DialogContext);

  return (
    <DialogPortal container={container}>
      <DialogOverlay />

      <Content
        ref={ref}
        className={`ui-dialog-content ${className}`}
        {...props}
      >
        {children}
      </Content>
    </DialogPortal>
  );
});

DialogContent.displayName = Content.displayName;

export { DialogContent };
