import { useRemoveProductFromFavoritesList } from "user_site/features/favorites/model";

import { FavoriteThinIcon } from "./FavoriteThinIcon";

export const RemoveFromFavoritesIconButton = ({
  productId,
  favoriteListId,
}: {
  favoriteListId: number;
  productId: number;
}) => {
  const { mutate } = useRemoveProductFromFavoritesList({
    productId,
    favoriteListId,
  });

  const handleRemoveFromFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <form className="no-left-padding" id="remove_product_favorite">
      <button
        className="save-to-favorites"
        type="button"
        onClick={handleRemoveFromFavoritesButtonClick}
      >
        <span className="product-button__icon">
          <FavoriteThinIcon />
        </span>

        <span className="product-button__description">
          <p>Remove from Favorites</p>
        </span>
      </button>
    </form>
  );
};
