/* eslint-disable import/no-internal-modules */
/* eslint-disable unicorn/filename-case */
/* eslint-disable boundaries/element-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";

import * as Menubar from "@radix-ui/react-menubar";

import { NavigationControlModalContentLayout } from "user_site/components";
import {
  AccountIconButtonV3,
  AccountModalV3,
} from "user_site/features/account";
import { CartIconButtonV3 } from "user_site/features/cart";
import {
  FavoritesModalV3,
  FavoritesIconButtonV3,
} from "user_site/features/favorites";
import {
  SiteSearchIconButtonV3,
  SiteSearchFormV3,
} from "user_site/features/site-search";
import { WithProviders } from "user_site/utils";

const MENU_STATES = {
  search: "search",
  favourites: "favourites",
  account: "account",
  closed: "",
} as const;

type MenuStates = (typeof MENU_STATES)[keyof typeof MENU_STATES];

const NavigationControlButtons = ({
  isSiteSearchAvailable,
}: {
  isSiteSearchAvailable: boolean;
}) => {
  const [currentMenuTab, setCurrentMenuTab] = useState<MenuStates>(
    MENU_STATES.closed
  );
  const portalContainer = document.querySelector("#header_and_navigation");

  const handleMenuStateChange = (state: string) => {
    setCurrentMenuTab(state as MenuStates);
  };
  const handleScroll = () => setCurrentMenuTab(MENU_STATES.closed);

  const preventOpenContentOnTriggerHover: React.PointerEventHandler<
    HTMLButtonElement
  > = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Menubar.Root
      asChild
      value={currentMenuTab}
      onValueChange={handleMenuStateChange}
    >
      <ul>
        {isSiteSearchAvailable && (
          <li>
            <Menubar.Menu value={MENU_STATES.search}>
              <Menubar.Trigger
                asChild
                onPointerEnter={preventOpenContentOnTriggerHover}
              >
                <SiteSearchIconButtonV3 />
              </Menubar.Trigger>
              <Menubar.Portal container={portalContainer}>
                <Menubar.Content>
                  <NavigationControlModalContentLayout id="search_tooltip">
                    <div id="navbar-v3-site-search">
                      <SiteSearchFormV3
                        onCloseSearch={() => setCurrentMenuTab("")}
                      />
                    </div>
                  </NavigationControlModalContentLayout>
                </Menubar.Content>
              </Menubar.Portal>
            </Menubar.Menu>
          </li>
        )}

        <li>
          <Menubar.Menu value={MENU_STATES.favourites}>
            <Menubar.Trigger
              asChild
              onPointerEnter={preventOpenContentOnTriggerHover}
            >
              <FavoritesIconButtonV3 />
            </Menubar.Trigger>
            <Menubar.Portal container={portalContainer}>
              <Menubar.Content>
                <FavoritesModalV3 />
              </Menubar.Content>
            </Menubar.Portal>
          </Menubar.Menu>
        </li>

        <li id="shopping_cart_btn">
          <CartIconButtonV3 />
        </li>

        <li>
          <Menubar.Menu value={MENU_STATES.account}>
            <Menubar.Trigger
              asChild
              onPointerEnter={preventOpenContentOnTriggerHover}
            >
              <AccountIconButtonV3 />
            </Menubar.Trigger>
            <Menubar.Portal container={portalContainer}>
              <Menubar.Content>
                <AccountModalV3 />
              </Menubar.Content>
            </Menubar.Portal>
          </Menubar.Menu>
        </li>
      </ul>
    </Menubar.Root>
  );
};

const Component = ({
  isSiteSearchAvailable,
}: {
  isSiteSearchAvailable: boolean;
}) => (
  <WithProviders>
    <NavigationControlButtons isSiteSearchAvailable={isSiteSearchAvailable} />
  </WithProviders>
);

export default Component;
