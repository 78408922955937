/* eslint-disable unicorn/filename-case */
// TODO: fix linter
// Add <%= javascript_pack_tag "user-site" %> in erb view to use this bundle
// and componenets provided in context

// eslint-disable-next-line import/no-internal-modules
import "../user_site/lib/react-query";

const componentRequireContext = require.context(
  "user_site/rails-components",
  true
);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactRailsUjs = require("react_ujs");

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUjs.useContext(componentRequireContext);
