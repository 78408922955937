/* eslint-disable import/no-internal-modules */
import { FavoriteListUpdateForm } from "user_site/features/favorites";
import { WithProviders } from "user_site/utils";

const Component = ({ favoriteListId }: { favoriteListId: number }) => (
  <WithProviders>
    <FavoriteListUpdateForm favoriteListId={favoriteListId} />
  </WithProviders>
);

export default Component;
