/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */
import { useQuery } from "@tanstack/react-query";

import { API } from "../../api";

interface Cart {
  id: string;
  itemsQuantity: number;
}

export const useCart = () =>
  useQuery<Cart | null>({
    queryKey: ["current_cart_query_key"],
    queryFn: () =>
      API.get("/api/v1/user_site/carts/current_cart")
        .then((r) => r.data)
        .catch(() => null),
  });
