/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef } from "react";

import { useRemoveProductFromFavoritesList } from "user_site/features/favorites/model";

export const RemoveProductFromFavoritesIconButton = ({
  productId,
  favoriteListId,
}: {
  favoriteListId: number;
  productId: number;
}) => {
  const { mutate } = useRemoveProductFromFavoritesList({
    productId,
    favoriteListId,
  });

  const linkRef = useRef<HTMLAnchorElement>(null);

  const handleRemoveFromFavoritesButtonClick = () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const confirmation = window.confirm("Are you sure?");

    if (confirmation) {
      mutate(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {},
        {
          onSuccess: () => {
            setTimeout(() => {
              linkRef.current?.closest("[data-product-favorite-id]")?.remove();
            }, 2000);
          },
        }
      );
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a
      ref={linkRef}
      title="remove from favorites"
      onClick={handleRemoveFromFavoritesButtonClick}
    >
      <i className="fa-regular fa-trash-can" />
    </a>
  );
};
