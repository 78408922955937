import { type ReactNode } from "react";

export function NavigationControlModalContentLayout({
  children,
  id,
  style,
}: {
  children: ReactNode;
  id: string;
  style?: React.CSSProperties;
}) {
  const headerAndHavigationElement = document.querySelectorAll(
    "#header_and_navigation"
  )[0] as HTMLDivElement | undefined;

  const backgroundColor = headerAndHavigationElement
    ? window.getComputedStyle(headerAndHavigationElement).backgroundColor
    : undefined;

  return (
    <div
      id={id}
      style={{
        margin: "auto",
        marginTop: "15px",
        width: "100vw",
        backgroundColor,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
