import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
} from "react";

import { Title } from "@radix-ui/react-dialog";

const DialogTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ ...props }, ref) => (
  <Title className="ui-dialog-title" ref={ref} {...props} />
));

DialogTitle.displayName = Title.displayName;

export { DialogTitle };
