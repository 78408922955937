import { forwardRef } from "react";

import { type CartButtonProps } from "../types";

const CartIconButtonSideBarInner = (props: CartButtonProps, ref: any) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    {...props}
    ref={ref}
    className="submit-form-btn soft"
    id="mobile_cart_link"
    href="#"
  >
    <i className="fa-solid fa-cart-shopping" />
    <i className="fa-solid fa-caret-right" />
  </a>
);

export const CartIconButtonSideBar = forwardRef(CartIconButtonSideBarInner);
