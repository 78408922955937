import { keepPreviousData, QueryClient } from "@tanstack/react-query";

declare global {
  interface Window {
    QUERY_CLIENT: QueryClient;
  }
}

// TODO: recheck this later locally
// const DEFAULT_RETRY_COUNT = 3;
// const DEFAULT_RETRY_DELAY = 4000; // 4 seconds

const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      placeholderData: keepPreviousData,
      staleTime: Number.POSITIVE_INFINITY,
      // retry: DEFAULT_RETRY_COUNT,
      // refetchInterval: DEFAULT_RETRY_DELAY,
    },
    // mutations: {
    //   retry: DEFAULT_RETRY_COUNT,
    //  retryDelay: DEFAULT_RETRY_DELAY,
    // },
  },
});

/** @description to share the same query client instance among microfrontends */
window.QUERY_CLIENT = QUERY_CLIENT;
