import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
} from "react";

import { Description } from "@radix-ui/react-dialog";

const DialogDescription = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({ ...props }, ref) => <Description ref={ref} {...props} />);

DialogDescription.displayName = Description.displayName;

export { DialogDescription };
