// eslint-disable-next-line import/no-internal-modules, boundaries/element-types
import { NavigationControlModalContentLayout } from "user_site/components";
import { useAccount } from "user_site/features/account/model";

export const AccountModal = () => {
  const { data: user } = useAccount();

  if (!user) return null;
  const actions = user.isGuest ? (
    <>
      <a
        className="column large-3"
        id="create_account_mobile_nav"
        href={user.metadata.createAccountUrl}
      >
        Create an Account
      </a>
      <a className="column large-3" href={user.metadata.loginUrl}>
        Login
      </a>
    </>
  ) : (
    <>
      <a
        className="column large-3 user-name"
        href={`/apps/users/${user.id}/account`}
      >
        {user.username || user.email || "User"}
      </a>
      <a id="logout" className="column large-3" href={user.metadata.logoutUrl}>
        Logout
      </a>
    </>
  );

  return (
    <NavigationControlModalContentLayout
      id="user_tooltip"
      style={{ border: "none", padding: "0 13%" }}
    >
      {actions}
    </NavigationControlModalContentLayout>
  );
};
