import { useAddProductToFavoritesList } from "user_site/features/favorites/model";

import { FavoriteThinIcon } from "./FavoriteThinIcon";

export const SaveToFavoritesIconButton = ({
  productId,
  favoriteListId,
}: {
  productId: number;
  favoriteListId: number;
}) => {
  const { mutate } = useAddProductToFavoritesList({
    productId,
    favoriteListId,
  });

  const handleAddProductToFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <form>
      <button
        className="save-to-favorites"
        type="button"
        onClick={handleAddProductToFavoritesButtonClick}
      >
        <span className="product-button__icon">
          <FavoriteThinIcon />
        </span>

        <span className="product-button__description">
          <p>
            Save to
            <br />
            Favorites
          </p>
        </span>
      </button>
    </form>
  );
};
