/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */
import { useAccount } from "user_site/features/account";

import { FavoriteThinIcon } from "./FavoriteThinIcon";

export const SaveToFavoritesGuestIconButton = () => {
  const { data: user } = useAccount();

  if (!user || !user.isGuest) return null;

  return (
    <a className="save-to-favorites" href={user.metadata.loginUrl}>
      <span className="product-button__icon">
        <FavoriteThinIcon />
      </span>

      <span className="product-button__description">
        <p>
          Save to
          <br />
          Favorites
        </p>
      </span>
    </a>
  );
};
