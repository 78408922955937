import { type LinkHTMLAttributes, forwardRef } from "react";

const AccountIconButtonInner = (props: LinkHTMLAttributes<any>, ref: any) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a {...props} ref={ref} className="navigation-icons" id="user-item" href="#">
    <img
      src="/assets/user_site/theme_v3/navigation_bar/login.png"
      alt="account"
    />
  </a>
);

export const AccountIconButton = forwardRef(AccountIconButtonInner);
