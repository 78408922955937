/* eslint-disable jsx-a11y/anchor-is-valid */

import { FavoriteThinIcon } from "./FavoriteThinIcon";
import { ManageFavoritesModal } from "./ManageFavoritesModal";

export const ManageFavoritesIconButton = ({
  productId,
}: {
  productId: number;
}) => (
  <ManageFavoritesModal
    trigger={
      <a>
        <span className="product-button__icon">
          <FavoriteThinIcon />
        </span>

        <span className="product-button__description">
          <p>Manage Favorites</p>
        </span>
      </a>
    }
    productId={productId}
  />
);
