import { forwardRef } from "react";

import { useCart } from "user_site/features/cart/model";

import { type CartButtonProps } from "../types";

const CartIconButtonInner = (props: CartButtonProps, ref: any) => {
  const { data: cart } = useCart();

  const itemsQuantity = cart?.itemsQuantity || 0;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      {...props}
      ref={ref}
      className="navigation-icons"
      id="cart-item"
      href="#"
    >
      <img
        src="/assets/user_site/theme_v3/navigation_bar/cart.webp"
        alt="account"
      />
      <div className="cart-quantity cart-quantity-js">{itemsQuantity}</div>
    </a>
  );
};

export const CartIconButton = forwardRef(CartIconButtonInner);
