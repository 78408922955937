import { Close, Portal, Trigger } from "@radix-ui/react-dialog";

import { DialogContent } from "./Content";
import { DialogDescription } from "./Description";
import { Dialog } from "./Dialog";
import { DialogFooter } from "./Footer";
import { DialogHeader } from "./Header";
import { DialogOverlay } from "./Overlay";
import { DialogTitle } from "./Title";

const DialogTrigger = Trigger;
const DialogPortal = Portal;
const DialogClose = Close;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
