import { forwardRef } from "react";

import { useCart } from "user_site/features/cart/model";

import { type CartButtonProps } from "../types";

const CartIconButtonSideBarInner = (props: CartButtonProps, ref: any) => {
  const { data: cart } = useCart();

  const itemsQuantity = cart?.itemsQuantity || 0;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      {...props}
      ref={ref}
      className="scrolling-navigation-cart-widget"
      href={itemsQuantity > 0 && cart ? `/shopping_cart/${cart.id}/edit` : "#"}
    >
      <img
        src="/assets/user_site/theme_v3/navigation_bar/cart.webp"
        alt="cart"
      />
    </a>
  );
};

export const CartIconButtonSideBar = forwardRef(CartIconButtonSideBarInner);
