/* eslint-disable boundaries/element-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useAccount } from "user_site/features/account";
import {
  useFavoritesLists,
  useUpdateFavoriteList,
} from "user_site/features/favorites/model";

export const FavoriteListUpdateForm = ({
  favoriteListId,
}: {
  favoriteListId: number;
}) => {
  const { data: user } = useAccount();
  const { data: favoriteLists } = useFavoritesLists();
  const { mutate: updateFavoriteList } = useUpdateFavoriteList({
    favoriteListId,
  });

  if (!user) return null;
  if (!favoriteLists) return null;

  const favoriteList = favoriteLists?.find(
    (list) => list.id === favoriteListId
  );

  if (!favoriteList) return null;

  const handleShareWithFrinedsCheckBoxClick = () => {
    updateFavoriteList({ isPrivate: !favoriteList.isPrivate });
  };

  return (
    <form className="update-favorite-list">
      <div className="user-form-field">
        <div className="user-form-field-label">Shareable with Friends?</div>
        <div className="switch" onClick={handleShareWithFrinedsCheckBoxClick}>
          <input
            type="radio"
            value="true"
            name="favorite_list[private]"
            defaultChecked={favoriteList.isPrivate}
          />
          <label htmlFor="x">No</label>
          <input
            type="radio"
            value="false"
            name="favorite_list[private]"
            defaultChecked={!favoriteList.isPrivate}
          />
          <label htmlFor="x1">Yes</label>
          <span />
        </div>
      </div>
      {!favoriteList.isPrivate && favoriteList.isPersisted && (
        <div
          className="user-form-field"
          id="share_favorite_list_button_container"
        >
          <div className="user-form-field-label">
            <a
              className="submit-form-btn share-favorite-list-form btn_theme"
              data-url={`/apps/users/${user.id}/favorite_lists/${favoriteListId}/share_form`}
              data-reveal-id="share_favorite_list_form"
            >
              Share this Page &nbsp;
              <i className="fa-solid fa-circle-arrow-right" />
            </a>
          </div>
        </div>
      )}
    </form>
  );
};
