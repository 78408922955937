export const FavoriteThinIcon = () => (
  <svg
    className="favorite-thin-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="390.9 168.5 513.7 457"
    xmlSpace="preserve"
  >
    <switch>
      <g>
        <path
          d="M898 275.9c-9.4-34.1-28.2-61.5-56-81.5-18.9-13.6-40.4-21.9-63.9-24.7-2.5-.3-5-.6-7.5-.8-1.1-.1-2.3-.2-3.4-.4h-13.1c-1.2.1-2.3.2-3.5.4-2.5.3-5.1.5-7.6.8-23.4 3-45.1 12.3-64.6 27.5-11.2 8.7-21.4 19.4-30.5 31.6-.1.1-.1.2-.2.2-.2-.3-.5-.6-.7-.9-14.1-18.8-30-33-48.6-43.4-15.6-8.7-32.3-13.9-49.8-15.6-1.7-.2-3.3-.3-5-.5l-2.3-.3h-13.1c-1.2.1-2.3.2-3.5.4-2.5.3-5.1.5-7.6.8-28.9 3.6-54.3 15.1-75.6 34.2-24.2 21.7-40 49.9-46.9 83.6-1.2 5.9-1.8 11.9-2.5 17.8-.3 2.7-.6 5.5-.9 8.2-.1.5-.2 1-.2 1.5l-.1.7v14.6l.1 1.1c.1.6.2 1.2.2 1.8.2 1.8.4 3.6.5 5.5.3 3.8.7 7.6 1.3 11.5 3.5 21.7 11.4 42.7 24.2 64 10.7 17.8 24.2 35.1 42.6 54.5 21.8 23.1 45.2 44.1 73.6 68.6 26.9 23.2 54.4 46.8 81 69.5l11.3 9.7c1.7 1.5 4 3.3 6.5 4.7 5 2.8 10.2 4.2 15.3 4.2 7.5 0 15.1-2.9 21.8-8.7l31.8-27.3 55.2-47.4c25.5-21.9 52.5-45.5 77-71.1 20.3-21.2 35-40.2 46.4-59.7 25.1-42.7 31.2-88.2 18.3-135.1zm-11.7 65.8c-2.2 18.3-7.8 35.8-17.2 53.6-8.7 16.6-20.5 33.3-36 50.8-16.6 18.9-36.3 38.2-60.1 58.9-23 20-46.5 40.2-69.2 59.7-10.3 8.9-20.7 17.7-31 26.6-2.9 2.5-5.8 5-8.7 7.6-2 1.7-3.9 3.4-5.9 5.1-6.9 6-13.9 6-20.7.2-9.9-8.5-19.7-17-29.6-25.5-32.2-27.8-65.5-56.5-98.1-85-22.3-19.5-41.1-38.9-57.6-59.3-14.5-17.9-25.2-35.3-32.6-52.9-9-21.5-12.9-44.3-11.4-67.7 1.7-27.4 9.6-51.8 23.5-72.5 19.9-29.6 47.2-47.6 81.1-53.6 7.7-1.3 15.2-2 22.6-2 29.2 0 55.6 10.6 78.8 31.6 10.8 9.8 19.4 21.3 27.2 32.3 1.1 1.5 2.2 3 3.3 4.6.6.8 1.1 1.5 1.7 2.4l1.2 1.7 1.2-1.7c.8-1.1 1.5-2.1 2.3-3.1 1.7-2.3 3.2-4.4 4.8-6.6 9.8-14 19.1-24.6 29.2-33.1 18.1-15.2 36.5-23.8 56.4-26.5 49-6.4 88.9 10.1 118.6 49.1 12.9 17 21.2 37 25.1 61 2.6 14.7 2.9 29.7 1.1 44.3z"
          fill="currentColor"
        />
      </g>
    </switch>
  </svg>
);
