import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from "react";

import { Overlay } from "@radix-ui/react-dialog";

const DialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ ...props }, ref) => (
  <Overlay ref={ref} className="ui-dialog-overlay" {...props} />
));

DialogOverlay.displayName = Overlay.displayName;

export { DialogOverlay };
