/* eslint-disable unicorn/filename-case */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef } from "react";

export const SiteSearchForm = ({
  onCloseSearch,
}: {
  onCloseSearch: () => void;
}) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleSearchButtonClick = () => {
    const value = inputRef.current?.value;

    if (value && value.length > 0) {
      formRef.current?.submit();
    } else {
      inputRef.current?.focus();
    }
  };

  return (
    <form
      action="/site_search"
      method="get"
      style={{ display: "flex" }}
      ref={formRef}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          e.preventDefault();

          handleSearchButtonClick();
        }
      }}
    >
      <input type="text" name="search" placeholder="Search..." ref={inputRef} />

      <div className="submit-search" onClick={handleSearchButtonClick} />
      <div className="undo-search" onClick={onCloseSearch} />
    </form>
  );
};
