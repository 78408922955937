import { type AxiosInstance } from "axios";

const getCsrfTokenHtmlElement = () =>
  document?.head?.querySelector("[name='csrf-token']");

export const getCsrfTokenFromDocument = () =>
  getCsrfTokenHtmlElement()?.getAttribute("content") ?? "";

export const setCsrfTokenToDocument = (token: string) => {
  const htmlElement = getCsrfTokenHtmlElement();

  htmlElement?.setAttribute("content", token);
};

export const getCsrfTokenFromBackend = (
  apiInstance: AxiosInstance
): Promise<string> =>
  apiInstance
    .get("/api/v1/authentication_token/renew_crsf_token")
    .then((res) => res.data.crsfToken);
