/* eslint-disable import/no-internal-modules */
import { SiteSearchFormMobileV3 } from "user_site/features/site-search";
import { WithProviders } from "user_site/utils";

const Component = () => (
  <WithProviders>
    <SiteSearchFormMobileV3 />
  </WithProviders>
);

export default Component;
