/**
 * @description this calls global js ajaxOverlayCustom function loaded by rails legacy bundle
 * @todo implement proper toasts for user site
 * */
export const toast = {
  /**
   *
   * @param duration milliseconds
   */
  success: ({
    message,
    duration = 1000,
  }: {
    message: string;
    duration?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ajaxOverlayCustom(`<div>${message}</div>`, duration);
  },
};
