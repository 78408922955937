/* eslint-disable import/no-internal-modules */
import { CartIconButtonSideBarV3 } from "user_site/features/cart";
import { WithProviders } from "user_site/utils";

const Component = () => (
  <WithProviders>
    <CartIconButtonSideBarV3 />
  </WithProviders>
);

export default Component;
