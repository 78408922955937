/* eslint-disable import/no-internal-modules */
/* eslint-disable boundaries/element-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useAccount } from "user_site/features/account";
import {
  ManageFavoritesIconButtonV3,
  RemoveFromFavoritesIconButtonV3,
  SaveToFavoritesGuestIconButtonV3,
  SaveToFavoritesIconButtonV3,
  useFavoritesLists,
} from "user_site/features/favorites";
import { WithProviders } from "user_site/utils";

const ProductPageFavoriteButton = ({ productId }: { productId: number }) => {
  const { data: user } = useAccount();
  const { data: favoriteLists } = useFavoritesLists();

  if (!user || !favoriteLists) return null;
  if (user.isGuest) return <SaveToFavoritesGuestIconButtonV3 />;

  if (favoriteLists.length > 1)
    return <ManageFavoritesIconButtonV3 productId={productId} />;

  const currentList = favoriteLists[0];

  if (!currentList) return null;

  if (currentList.favoritedProductsIds.find((i) => i === productId)) {
    return (
      <RemoveFromFavoritesIconButtonV3
        productId={productId}
        favoriteListId={currentList.id}
      />
    );
  }

  return (
    <SaveToFavoritesIconButtonV3
      productId={productId}
      favoriteListId={currentList.id}
    />
  );
};

const Component = ({ productId }: { productId: number }) => (
  <WithProviders>
    <ProductPageFavoriteButton productId={productId} />
  </WithProviders>
);

export default Component;
