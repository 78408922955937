/* eslint-disable unicorn/filename-case */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-imports */
/* eslint-disable import/no-internal-modules */
/* eslint-disable boundaries/element-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line boundaries/element-types
import { useRef, useState } from "react";

import { NavigationControlModalContentLayout } from "../../../../components";
import { useAccount } from "../../../account";
import {
  type FavoriteList,
  useAddFavoriteList,
  useFavoritesLists,
} from "../../model";

const FavoriteListItem = ({ list }: { list: FavoriteList }) => (
  <a href={list.url} key={list.id}>
    <div className="favorite-list-link-container">
      <div className="favorite-list-name">{list.name}</div>

      <div className="favorite-list-products-count">
        {list.productFavoritesCount}
      </div>
    </div>
  </a>
);

const NewFavoriteListForm = ({ onClose }: { onClose: () => void }) => {
  const { data: user } = useAccount();

  const { mutate: addFavoriteList } = useAddFavoriteList();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleAddFavoriteList = () => {
    const value = inputRef.current?.value;

    if (value && value.length > 0) {
      addFavoriteList({ name: value }, { onSuccess: onClose });
    } else {
      inputRef.current?.focus();
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      handleAddFavoriteList();
    }
  };

  if (!user || user.metadata.disableGuestUserCreationFeatureEnabled)
    return null;

  return (
    <div
      className="navbar-v3-favorite-list"
      id="navbar-v3-add-favorite-list-tooltip"
    >
      <form className="new_favorite_list" id="new_favorite_list">
        <input
          placeholder="Favorite List Name ..."
          className="column large-8"
          id="favorite_list_name"
          ref={inputRef}
          onKeyDown={handleInputKeyDown}
        />

        <div
          className="submit-add-fovorite-form column large-2"
          onClick={handleAddFavoriteList}
        />
        <div
          className="close-add-fovorite-form column large-2"
          onClick={onClose}
        />
      </form>
    </div>
  );
};

const ActionsList = ({
  onAddFavoriteList,
}: {
  onAddFavoriteList: () => void;
}) => {
  const { data: user } = useAccount();
  const { data: favoriteLists } = useFavoritesLists();

  if (!user || !favoriteLists) return null;

  return (
    <div
      className="navbar-v3-favorite-list"
      id="navbar-v3-manage-favorite-lists-tooltip"
    >
      {favoriteLists.length > 1 ? (
        <div className="column large-4">
          <div className="favorite-lists-dropdown">
            <button type="button" className="favorite-lists-dropdown-button">
              Choose favorite list
            </button>
            <div className="favorite-lists-dropdown-content">
              {favoriteLists.map((list) => (
                <FavoriteListItem list={list} key={list.id} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="column large-4">
          {favoriteLists.length > 0 && (
            <FavoriteListItem list={favoriteLists[0] as FavoriteList} />
          )}
        </div>
      )}

      <div className="column large-4">
        <a href="#" id="add_favorite_list" onClick={onAddFavoriteList}>
          Add Favorite List
        </a>
      </div>

      <div className="column large-4">
        {user.metadata.disableGuestUserCreationFeatureEnabled ? (
          <a
            className="edit-button"
            href="#"
            data-confirm="Is not available right now"
          >
            Manage Favorite Lists
          </a>
        ) : (
          <a
            className="edit-button"
            href={`/apps/users/${user.id}/favorite_lists`}
          >
            Manage Favorite Lists
          </a>
        )}
      </div>
    </div>
  );
};

export const FavoritesModal = () => {
  const [isNewFavoriteListFormOpened, setIsNewFavoriteListFormOpened] =
    useState(false);

  const handleAddFavoriteList = () => {
    setIsNewFavoriteListFormOpened(true);
  };

  const handleFavouriteListFormClose = () => {
    setIsNewFavoriteListFormOpened(false);
  };

  return (
    <NavigationControlModalContentLayout id="favorite_tooltip">
      {isNewFavoriteListFormOpened ? (
        <NewFavoriteListForm onClose={handleFavouriteListFormClose} />
      ) : (
        <ActionsList onAddFavoriteList={handleAddFavoriteList} />
      )}
    </NavigationControlModalContentLayout>
  );
};
