/* eslint-disable unicorn/no-nested-ternary */
/* eslint-disable boundaries/element-types */
/* eslint-disable no-restricted-imports */
import { type ReactNode } from "react";

import { useRemoveProductFromFavoritesList } from "user_site/features/favorites/model";
import { useAddProductToFavoritesList } from "user_site/features/favorites/model";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../../components";
import { useFavoritesLists } from "../../model";

const RemoveFromFavorites = ({
  favoriteListId,
  productId,
}: {
  favoriteListId: number;
  productId: number;
}) => {
  const { mutate } = useRemoveProductFromFavoritesList({
    productId,
    favoriteListId,
  });

  const handleRemoveFromFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <form className="no-left-padding" id="remove_product_favorite">
      <button
        className="submit-form-btn manage-product-favorite secondary-button"
        type="button"
        onClick={handleRemoveFromFavoritesButtonClick}
      >
        <i className="fa-solid fa-heart warning" />
        <p> Remove from Favorites </p>
      </button>
    </form>
  );
};

const SaveToFavorites = ({
  favoriteListId,
  productId,
}: {
  favoriteListId: number;
  productId: number;
}) => {
  const { mutate } = useAddProductToFavoritesList({
    productId,
    favoriteListId,
  });

  const handleAddProductToFavoritesButtonClick = () => {
    mutate();
  };

  return (
    <form>
      <button
        className="submit-form-btn manage-product-favorite secondary-button"
        type="button"
        onClick={handleAddProductToFavoritesButtonClick}
      >
        <i className="fa-regular fa-heart" />
        <p> Save to Favorites </p>
      </button>
    </form>
  );
};

const ManageFavoritesModalContent = ({ productId }: { productId: number }) => {
  const { data: favoriteLists } = useFavoritesLists();

  if (!favoriteLists) return null;

  return (
    <>
      <h2>Manage Favorites</h2>
      <p
        className="subheading"
        style={{
          marginTop: "-15px",
          marginBottom: "20px",
        }}
      >
        Below, select which favorite lists you would like to save this product
        into.
      </p>
      <div
        id="manage_product_favorite_modal_container"
        style={{ height: "100%", overflow: "auto" }}
      >
        <div className="large-12 columns global-form-details">
          <table className="asf">
            <thead>
              <tr>
                {/*
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                 @ts-ignore */}
                <th width="80">
                  <i
                    className="fa-solid fa-heart"
                    style={{ marginRight: "10px" }}
                  />
                  Favorite List Name
                </th>
                {/*
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                 @ts-ignore */}
                <th width="70">Saved Count</th>
                {/*
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                 @ts-ignore */}
                <th width="50">View</th>
                {/*
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                 @ts-ignore */}
                <th width="5">
                  Add to favorite
                  <i
                    className="fa-solid fa-heart warning"
                    style={{ marginLeft: "10px" }}
                  />
                </th>
              </tr>
            </thead>
            <tbody style={{ fontWeight: "bold" }}>
              {favoriteLists.map((favoriteList) => (
                <tr key={favoriteList.id}>
                  <td>
                    <a href={favoriteList.url}>{favoriteList.name}</a>
                  </td>
                  <td>{favoriteList.productFavoritesCount}</td>
                  <td>
                    <a href={favoriteList.url}>View</a>
                  </td>
                  <td>
                    {favoriteList.favoritedProductsIds.includes(productId) ? (
                      <RemoveFromFavorites
                        productId={productId}
                        favoriteListId={favoriteList.id}
                      />
                    ) : (
                      <SaveToFavorites
                        productId={productId}
                        favoriteListId={favoriteList.id}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export const ManageFavoritesModal = ({
  trigger,
  productId,
}: {
  trigger: ReactNode;
  productId: number;
}) => (
  <Dialog renderToBody>
    <DialogTrigger asChild>{trigger}</DialogTrigger>
    <DialogContent id="manage_product_favorite_modal">
      <DialogClose asChild>
        <button
          aria-label="close"
          type="button"
          style={{
            fontFamily: "inherit",
            border: "none",
            backgroundColor: "inherit",
            zIndex: 10,
            lineHeight: 1,
            position: "absolute",
            top: 0,
            right: 0,
            padding: "10px 20px",
            fontSize: "25px",
            color: "black",
          }}
        >
          &#215;
        </button>
      </DialogClose>
      <ManageFavoritesModalContent productId={productId} />
    </DialogContent>
  </Dialog>
);
