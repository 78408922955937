import { forwardRef } from "react";

import { useCart } from "user_site/features/cart/model";

import { type CartButtonProps } from "../types";

const CartIconButtonInner = (props: CartButtonProps, ref: any) => {
  const { data: cart } = useCart();

  const itemsQuantity = cart?.itemsQuantity || 0;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a {...props} ref={ref} className="submit-form-btn soft" href="#">
      {`(${itemsQuantity})`}
      <i className="fa-solid fa-cart-shopping" />
      Cart
      <i className="fa-solid fa-caret-right" />
    </a>
  );
};

export const CartIconButton = forwardRef(CartIconButtonInner);
