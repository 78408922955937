import { RemoveProductFromFavoritesIconButton } from "user_site/features/favorites";

import { WithProviders } from "../utils";

const Component = ({
  productId,
  favoriteListId,
}: {
  favoriteListId: number;
  productId: number;
}) => (
  <WithProviders>
    <RemoveProductFromFavoritesIconButton
      productId={productId}
      favoriteListId={favoriteListId}
    />
  </WithProviders>
);

export default Component;
