import { forwardRef } from "react";

import { useCart } from "user_site/features/cart/model";

import { type CartButtonProps } from "../types";

const CartIconButtonMobileInner = (props: CartButtonProps, ref: any) => {
  const { data: cart } = useCart();

  const itemsQuantity = cart?.itemsQuantity || 0;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      {...props}
      ref={ref}
      className="mobile_cart_link"
      id="mobile_cart_link"
      href="#"
    >
      <i className="fa-solid fa-cart-shopping" style={{ fontSize: "2em" }} />
      <span className="cart-quantity cart-quantity-js">{itemsQuantity}</span>
    </a>
  );
};

export const CartIconButtonMobile = forwardRef(CartIconButtonMobileInner);
