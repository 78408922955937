import axios, {
  AxiosHeaders,
  type InternalAxiosRequestConfig,
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";
import camelcaseKeys from "camelcase-keys";

import { getCsrfTokenFromDocument } from "./csrf-token";

declare module "axios" {
  // eslint-disable-next-line no-shadow
  interface AxiosRequestConfig {
    skipCamelCaseReponseKeys?: boolean;
  }
}

const requestTimeout = 10000; // 10 seconds

const axiosBaseRequestConfig: AxiosRequestConfig = {
  timeout: requestTimeout,
  headers: {
    "content-type": "application/json;charset=utf-8",
    accept: "application/json;charset=utf-8",
  },
};

const setupAxios = () => {
  const instance = axios.create(axiosBaseRequestConfig);

  const onRequest = (
    config: InternalAxiosRequestConfig<any>
  ): InternalAxiosRequestConfig<any> => {
    const csrfToken = getCsrfTokenFromDocument();

    return {
      ...config,
      withCredentials: true,
      baseURL: window.location.origin,
      headers: new AxiosHeaders({
        ...config.headers,
        "X-Csrf-Token": csrfToken,
      }),
    };
  };

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    const data = response.config.skipCamelCaseReponseKeys
      ? response.data
      : camelcaseKeys(response.data, { deep: true });

    return {
      ...response,
      data,
    };
  };

  const onRequestError = async (error: AxiosError): Promise<AxiosError> =>
    /* const { config, request } = error;

    if (request.status === 401 && config) {
      return getCsrfTokenFromBackend(instance)
        .then((token) => {
          setCsrfTokenToDocument(token);
        })
        .then(() => instance(config));
    } */

    Promise.reject(error);
  const onResponseError = onRequestError;

  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);

  return instance;
};

/** @todo replace with smth more lightweight */
export const API = setupAxios();
